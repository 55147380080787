import "bootstrap-icons/font/bootstrap-icons.css";
import opensea from "../assets/opensea.png";

const Navbar = () => {
  return (
    <div className="container-fluid text-center nav">
      <div className="container hstack justify-content-center">
        <a
          href="https://discord.gg/c8FwpmV3JM"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="bi bi-discord"></i>
        </a>
        <a
          href="https://twitter.com/OnejungleOrg"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="bi bi-twitter"></i>
        </a>
        <a
          href="https://opensea.io/collection/lumberjack-larry-v2"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={opensea}
            alt="OpenSea"
            style={{
              width: "24px",
            }}
          />
        </a>
      </div>
    </div>
  );
};

export default Navbar;
